<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ enquiryId ? 'Update' : 'Add' }} inquiry
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \
                  Inquiry \ {{ enquiryId ? 'Update' : 'Add' }} inquiry
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="enquiry.first_name"
                    :error="$v.enquiry.first_name.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>First name <span class="text-danger">*</span> </template>
                </v-text-field>
                <span class="text-danger" v-if="errors.first_name" >**{{errors.first_name[0]}}</span>
                <span class="text-danger" v-if="$v.enquiry.first_name.$error" >This Field is Required</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="enquiry.last_name"
                    :error="$v.enquiry.last_name.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>Last name <span class="text-danger">*</span> </template>
                </v-text-field>
                <span class="text-danger" v-if="errors.last_name" >**{{errors.last_name[0]}}</span>
                <span class="text-danger" v-if="$v.enquiry.last_name.$error" >This Field is Required</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="enquiry.email"
                    :error="$v.enquiry.email.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>Email <span class="text-danger" v-if="$v.enquiry.email.$error">*</span> </template>
                </v-text-field>
                <span  class="text-danger"  v-if="!$v.enquiry.email.$error"> Either email or phone is required</span>
                <span class="text-danger" v-if="errors.email" >**{{errors.email[0]}}</span>
                <span class="text-danger" v-if="$v.enquiry.email.$error" >Either Email Or Phone is Required</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    v-model="enquiry.phone"
                    :error="$v.enquiry.phone.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>Phone No. <span  v-if="$v.enquiry.phone.$error" class="text-danger">*</span> </template>
                </v-text-field>
                <span class="text-danger" v-if="errors.phone" >**{{errors.phone[0]}}</span>
                <span class="text-danger" v-if="$v.enquiry.phone.$error" >Either Email Or Phone is Required</span>
              </v-col>

              <v-col cols="12" md="6">
                  <v-radio-group
                          v-model="enquiry.gender"
                          :error="$v.enquiry.gender.$error"
                          row
                  >
                      <label>Gender</label>
                      <v-radio
                              label="Male"
                              value="male"
                      ></v-radio>
                      <v-radio
                              label="Female"
                              value="female"
                      ></v-radio>
                  </v-radio-group>
                  <span class="text-danger" v-if="errors.gender" >**{{errors.gender[0]}}</span>
                  <span class="text-danger" v-if="$v.enquiry.gender.$error">This information is required</span>
              </v-col>

              <v-col cols="12" md="6" v-if="currentUser && currentUser.access_type == 'head'">
                <v-select
                    v-model="enquiry.branch_id"
                    :items="ourBranches"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                >
                  <template v-slot:label>Branch<span class="text-danger"></span> </template>
                </v-select>
                <span class="text-danger" v-if="$v.enquiry.category_id.$error">Category is Required</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                    v-model="enquiry.category_id"
                    :error="$v.enquiry.category_id.$error"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                >
                  <template v-slot:label>Lead category<span class="text-danger"> *</span> </template>
                </v-select>
                <span class="text-danger" v-if="$v.enquiry.category_id.$error">Category is Required</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                    v-model="enquiry.reason_for_visit"
                    :error="$v.enquiry.reason_for_visit.$error"
                    :items="purpose_for_visits"
                    item-text="title"
                    item-value="id"
                    outlined
                    dense
                >
                  <template v-slot:label>Reason for visit<span class="text-danger"> *</span> </template>
                </v-select>
                <span class="text-danger" v-if="$v.enquiry.reason_for_visit.$error">This field is required</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                    v-model="enquiry.current_visa"
                    :error="$v.enquiry.current_visa.$error"
                    :items="current_visas"
                    item-text="title"
                    item-value="id"
                    outlined
                    dense
                >
                  <template v-slot:label>Current visa status<span class="text-danger"> *</span> </template>
                </v-select>
                <span class="text-danger" v-if="$v.enquiry.current_visa.$error">This field is required</span>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                    v-model="enquiry.address"
                    outlined
                    dense
                >
                  <template v-slot:label>Address </template>
                </v-text-field>

              </v-col>


              <v-col cols="12">
                Remarks <span class="text-danger">*</span>
                <ckeditor
                    :config="editorConfig"
                    v-model="enquiry.description"
                >
                </ckeditor>
                <span class="text-danger" v-if="$v.enquiry.description.$error">Description Field is required</span>
                <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                Follow up required
                <v-switch
                    @change="changeRequiredFollowup"
                    v-model="enquiry.requires_followup"
                    :label="enquiry.requires_followup ? 'Yes' : 'No'"
                ></v-switch>
              </v-col>

              <v-col cols="12" v-if="enquiry.requires_followup">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-menu
                        ref="menu"
                        v-model="menuFollowUpDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="enquiry.follow_up_required_date"
                            :error="$v.enquiry.follow_up_required_date.$error"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                        >
                          <template v-slot:label>Followup Required Date <span class="text-danger">*</span> </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                          v-model="enquiry.follow_up_required_date"
                          outlined
                          dense
                          no-title
                          @input="menuFollowUpDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <strong>Attachments</strong>
                <div class="mt-2" v-if="enquiry.attachment_paths && enquiry.attachment_paths.length > 0">
                  <ul class="ml-10 font-size-lg">
                      <li v-for="(attachment, index) in enquiry.attachment_paths" :key="index">
                          <div class="d-flex d-flex-row">
                              <div>
                                {{attachment.name}}
                              </div>
                              <div class="mx-4">
                                <a v-if="attachment && attachment.file_path" :href="attachment.file_path.real" target="_blank"><v-icon class="mx-1">fas fa-file-alt</v-icon>File</a>
                              </div>
                          </div>
                      </li>
                  </ul>
                </div>
                <v-row class="mt-3">
                  <v-col cols="12" md="8">
                    <v-text-field
                        label="Attachment name"
                        v-model="attachmentName"
                        :error="$v.attachmentName.$error"
                        outlined
                        dense
                    ></v-text-field>
                    <span class="text-danger" v-if="$v.attachmentName.$error">This Field is required</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn
                        color="btn btn-outline"
                        dark
                        @click="addField()"
                    >
                      Add file
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="enquiry.attachments && enquiry.attachments.length > 0">
                <v-row v-for="(attachment, index) in enquiry.attachments" :key="index">
                  <v-col cols="12" md="5">
                    <v-text-field
                        label="Attachment name"
                        v-model="attachment.attachmentName"
                        disabled
                        readonly
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" v-if="edit && attachment.url">
                    <v-text-field
                        label="URL"
                        v-model="attachment.url"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6"  v-if="edit && attachment && attachment.file_path">
                  <span>
                    <a class="mt-4 font-size-lg" :href="attachment.file_path.thumb" target="_blank"><v-icon class="mx-1" >fas fa-link</v-icon>File</a>
                  </span>
                  </v-col>

                  <v-col cols="12" md="6">

                    <v-file-input
                        label="Select file to upload"
                        v-model="attachment.file"
                        :error="$v.enquiry.attachments.$each[index].file.$error"
                        prepend-icon=""
                        outlined
                        dense
                    >
                    </v-file-input>
                    <span class="text-danger" v-if="$v.enquiry.attachments.$each[index].file.$error">File Field is required</span>
                  </v-col>

                  <v-col cols="12" md="1">
                    <v-icon small class="mt-3 cursor-pointer" color="red darken-2" @click="deleteAttachment(index)">fas fa-trash-alt</v-icon>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                    v-model="enquiry.status"
                    :error="$v.enquiry.status.$error"
                    :items="statusTypes"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                >
                  <template v-slot:label>Status <span class="text-danger">*</span> </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                    v-model="enquiry.priority"
                    :error="$v.enquiry.priority.$error"
                    :items="priorities"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                >
                  <template v-slot:label>Priority <span class="text-danger">*</span> </template>
                </v-select>
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12 text-right">
                  <v-btn
                          @click="cancel"
                          class="mr-2 btn btn-standard text-white"
                  >Cancel
                  </v-btn>
                <v-btn
                    :loading="loading"
                    @click="createOrUpdate"
                    class="btn btn-primary text-white"
                    v-if="checkIsAccessible('enquiry', 'create') || checkIsAccessible('enquiry', 'edit')"
                >{{ enquiryId ? 'Update' : 'Add' }}
                </v-btn>

              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import EnquiryService from "@/services/enquiry/EnquiryService";
import LeadCategoryService from "@/services/enquiry/lead/category/LeadCategoryService";
import PurposeForVisitService from "@/services/purpose-for-visit/PurposeForVisitService";
import VisaTypeService from "@/services/visa/type/VisaTypeService";

const enquiry = new EnquiryService();
const visa = new VisaTypeService();
const leadCategory = new LeadCategoryService();
const purposeForVisit = new PurposeForVisitService();
export default {
  name: "CreateOrUpdate",
  data() {
    return {
      title: '',
      attachmentName: null,
      type: null,
      errors: [],
      edit: false,
      dialog: false,
      loading: false,
      menuFollowUpDate: false,
      categories: [],
      purpose_for_visits: [],
      enquiry: {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: '',
        gender: 'male',
        address: '',
        description: '',
        reason_for_visit: '',
        priority: 'low',
        requires_followup: false,
        follow_up_required_date: '',
        status: 'open',
        attachments: [],
      },
      priorities: [
        { name: 'Low', value: 'low' },
        { name: 'Medium', value: 'medium' },
        { name: 'High', value: 'high' }
      ],
      current_visas: [],
      types: [
        { name: 'URL', value: 'url' },
        { name: 'File', value: 'file' },
      ],
      statusTypes: [
        {name: 'Open', value: 'open'},
        {name: 'Closed', value: 'closed'},
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList'],
        ]
      }
    }
  },
  validations: {
    attachmentName: { required },
    type: { required },
    enquiry: {
      first_name: { required },
      last_name: { required },
      email: { required: requiredIf(function () {
          return this.enquiry.phone=='';
        })
      },
      phone: { required: requiredIf(function () {
          return this.enquiry.email=='';
        })
      },
      category_id: { required },
      reason_for_visit: { required },
      current_visa: { required },
      gender: { required },
      description: { required },
      priority: { required },
      status: { required },
      attachments: {
        $each : {
          file: {
            required
          }
        }
      },
      follow_up_required_date: { required: requiredIf(function () {
          return this.enquiry.requires_followup;
        })
      },

    }
  },
  computed: {
    enquiryId() {
      return this.$route.params.enquiryId;
    }
  },
  mounted() {
    this.getAllCategories();
    this.getAllPurposeForVisit();
    this.getAllVisaType();
    this.getAllBranches();
    if(this.enquiryId) {
      this.getEnquiry();
    }
  },

  methods: {
    getEnquiry() {
      enquiry
          .show(this.enquiryId)
          .then(response => {
            this.enquiry = response.data.enquiry;
          })
          .catch(() => {});
    },

    getAllPurposeForVisit() {
      purposeForVisit
          .all()
          .then(response => {
            this.purpose_for_visits = response.data.data;
          })
          .catch(() => {});
    },

    getAllVisaType() {
      visa
          .all()
          .then(response => {
            this.current_visas = response.data.visa_types;
          })
          .catch(() => {});
    },

    cancel() {
      this.errors = [];
      this.$tabs.close().then(() => {
        this.resetForm();
        this.$tabs.open({name: "enquiry"})
      })
    },

    changeRequiredFollowup() {
      if(!this.enquiry.requires_followup) {
        this.enquiry.follow_up_required_date = '';
      }
    },

    addField() {
      this.$v.attachmentName.$touch();
      this.$v.type.$touch();
      if(this.$v.attachmentName.$error && this.$v.type.$error){
        setTimeout(() => {
          this.$v.attachmentName.$reset();
          this.$v.type.$reset();
        }, 3000);
      } else {
        if (this.attachmentName != null && this.enquiry.attachments.length < 3) {
          this.enquiry.attachments.push({
            attachmentName: this.attachmentName,
            file: null,
          });
          this.attachmentName = null;
          this.$v.attachmentName.$reset();
        }
      }
    },

    deleteAttachment(index) {
      this.enquiry.attachments.splice(index, 1);
    },

    convertToFormData() {
      let formData = new FormData();
      for (let key in this.enquiry) {
        if (key === "attachments" && this.enquiry[key] != null && this.enquiry[key] != undefined) {
          for (let i = 0; i < this.enquiry[key].length; i++) {
            formData.append(`attachment_title_${i+1}`, this.enquiry[key][i].attachmentName);
            if(this.enquiry[key][i].file != null) {
              formData.append(`attachment_${i+1}`, this.enquiry[key][i].file);
            }
          }
        }else {
          if(this.enquiry[key]){
            formData.append(key,this.enquiry[key]);
          }
        }
      }
      return formData;
    },

    createOrUpdate() {
      this.$v.enquiry.$touch()
      if (this.$v.enquiry.$error) {
        setTimeout(() => {
          this.$v.enquiry.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if(!this.enquiryId) this._create();
        else this.update();
      }
    },

    getAllCategories() {
      leadCategory
          .all()
          .then(response => {
            this.categories = response.data.leadCategories;
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },

    _create() {
      let form = this.convertToFormData();
      enquiry
          .create(form)
          .then(response => {
            this.$snotify.success("Enquiry Created successfully");
            this.cancel();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },

    update() {
      let form = this.convertToFormData();
      enquiry
          .update(this.enquiry.id, form)
          .then(response => {
            this.$snotify.success("Enquiry Update successfully");
            this.cancel();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },

    resetForm() {
      this.errors=[];
      this.$v.$reset();
      this.attachmentName = null;
      this.type = null;
      this.enquiry = {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: '',
        reason_for_visit: '',
        gender: 'male',
        address: '',
        description: '',
        priority: 'low',
        requires_followup: false,
        follow_up_required_date: '',
        status: 'open',
        attachments: [],
      };
    },
  }
}
</script>
